import React from 'react';
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  makeStyles,
  Chip,
} from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef, EntityRefLink } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';

const useStyles = makeStyles(theme => ({
  section: {
    marginBottom: theme.spacing(4),
  },
  card: {
    height: '100%',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  chipContinue: {
    backgroundColor: '#1abc9c',
    color: theme.palette.common.white,
  },
  chipSleep: {
    backgroundColor: '#f39c12',
    color: theme.palette.common.white,
  },
  chipStart: {
    backgroundColor: '#3498db',
    color: theme.palette.common.white,
  },
  chipTBD: {
    backgroundColor: '#95a5a6',
    color: theme.palette.common.white,
  },
  chipTest: {
    backgroundColor: '#9b59b6',
    color: theme.palette.common.white,
  },
  contentCard: {
    padding: theme.spacing(2),
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
  },
  sectionCard: {
    height: '100%',
  },
  trainingCard: {
    marginBottom: theme.spacing(2),
  },
  sectionHeader: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  trainingCardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  trainingInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  trainingTitle: {
    fontWeight: 500,
  },
  serviceLine: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
  },
}));

const getStatusChipClass = (classes: Record<string, string>, status?: string) => {
  switch (status?.toLowerCase()) {
    case 'continue': return classes.chipContinue;
    case 'sleep (shop)': return classes.chipSleep;
    case 'start': return classes.chipStart;
    case 'tbd': return classes.chipTBD;
    case 'test': return classes.chipTest;
    default: return '';
  }
};

export const ConsultantTrainingsPanel = ({ userName }: { userName: string }) => {
  const classes = useStyles();
  const catalogApi = useApi(catalogApiRef);
  const [ownedTrainings, setOwnedTrainings] = React.useState<Entity[]>([]);
  const [trainerTrainings, setTrainerTrainings] = React.useState<Entity[]>([]);

  React.useEffect(() => {
    const fetchTrainings = async () => {
      const { items } = await catalogApi.getEntities({
        filter: {
          kind: 'Resource',
          'spec.type': 'training',
        },
      });

      setOwnedTrainings(items.filter(entity => 
        entity.metadata.annotations?.['monday.com.field-owner']?.toLowerCase().includes(userName.toLowerCase())
      ));

      setTrainerTrainings(items.filter(entity => 
        entity.metadata.annotations?.['monday.com.field-trainers']?.toLowerCase().includes(userName.toLowerCase())
      ));
    };

    if (userName) {
      fetchTrainings();
    }
  }, [catalogApi, userName]);

  const TrainingCard = ({ training }: { training: Entity }) => (
    <Card className={classes.card}>
      <div className={classes.trainingCardContent}>
        <div className={classes.trainingInfo}>
          <Typography className={classes.trainingTitle}>
            <EntityRefLink entityRef={training} />
          </Typography>
          <Typography className={classes.serviceLine}>
            {training.metadata.annotations?.['monday.com.field-serviceline'] || 'No service line'}
          </Typography>
        </div>
        <Chip
          label={training.metadata.annotations?.['monday.com.field-status'] || 'Unknown'}
          size="small"
          className={`${classes.chip} ${getStatusChipClass(classes, training.metadata.annotations?.['monday.com.field-status'])}`}
        />
      </div>
    </Card>
  );

  return (
    <Grid container spacing={3}>
      {/* Owner Section */}
      <Grid item xs={12} md={6}>
        <Card className={classes.sectionCard}>
          <CardHeader
            className={classes.sectionHeader}
            title={
              <Typography variant="h5">
                Training Owner
              </Typography>
            }
          />
          <CardContent>
            {ownedTrainings.length > 0 ? (
              ownedTrainings.map(training => (
                <div key={training.metadata.name} className={classes.trainingCard}>
                  <TrainingCard training={training} />
                </div>
              ))
            ) : (
              <Typography color="textSecondary">
                No trainings owned by this consultant
              </Typography>
            )}
          </CardContent>
        </Card>
      </Grid>

      {/* Trainer Section */}
      <Grid item xs={12} md={6}>
        <Card className={classes.sectionCard}>
          <CardHeader
            className={classes.sectionHeader}
            title={
              <Typography variant="h5">
                Training Trainer 
              </Typography>
            }
          />
          <CardContent>
            {trainerTrainings.length > 0 ? (
              trainerTrainings.map(training => (
                <div key={training.metadata.name} className={classes.trainingCard}>
                  <TrainingCard training={training} />
                </div>
              ))
            ) : (
              <Typography color="textSecondary">
                No trainings where consultant is a trainer
              </Typography>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
