import { createApiRef } from '@backstage/core-plugin-api';

export interface SkillsApi {
    removeUserSkill(userId: string, name: string): Promise<void>;
    getUserSkills(userId: string): Promise<UserSkill[]>;
    addUserSkill(userId: string, name: string, proficiency: number): Promise<UserSkill>;
}
  
export interface UserSkill {
  id: string;
  userId: string;
  name: string;
  proficiency: number;
}

export const skillsApiRef = createApiRef<SkillsApi>({
    id: 'plugin.skills.service',
});
