// ConsultantsCatalogPage.tsx

import React from 'react';
import { Content } from '@backstage/core-components';
import { EntityTable, EntityRefLink } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import { TableColumn } from '@backstage/core-components';
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Typography,
  CircularProgress,
  Box,
  Chip,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { 
  discoveryApiRef, 
  useApi,
  identityApiRef } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  activeChip: {
    backgroundColor: '#007bff', // green
    color: 'white',
  },
  availableChip: {
    backgroundColor: '#4caf50', // red
    color: 'white',
  },
  filterGrid: {
    marginBottom: theme.spacing(4), // Add bottom margin to filter grid
  },
  tableContainer: {
    marginTop: theme.spacing(4), // Add top margin to table
  },
  searchWrapper: {
    position: 'relative',
    width: '100%',
  },
  searchProgress: {
    position: 'absolute',
    right: '48px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  searchIcon: {
    position: 'absolute',
    right: '8px',
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
  },
  skillChip: {
    marginRight: theme.spacing(1),
    backgroundColor: "#9b59b6",
    color: theme.palette.common.white,
  },
}));

interface Skill {
  name: string;
  proficiency: number;
}

const TopSkillsCell = ({ userId }: { userId: string }) => {
  const classes = useStyles();
  const [skills, setSkills] = React.useState<Skill[]>([]);
  const [loading, setLoading] = React.useState(true);
  const discoveryApi = useApi(discoveryApiRef);
  const identityApi = useApi(identityApiRef);

  React.useEffect(() => {
    const loadSkills = async () => {
      try {
        setLoading(true);
        const baseUrl = await discoveryApi.getBaseUrl('skills');
        const { token } = await identityApi.getCredentials();

        const response = await fetch(`${baseUrl}/users/${userId}/skills`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch skills');
        }
        
        const data = await response.json();
        if (!data || data.length === 0) {
          setSkills([]);
          return;
        }
  
        const topSkills = data
          .sort((a: Skill, b: Skill) => b.proficiency - a.proficiency)
          .slice(0, 5);
        setSkills(topSkills);
      } catch (error) {
        console.error('Failed to load skills:', error);
      } finally {
        setLoading(false);
      }
    };
    loadSkills();
  }, [discoveryApi, userId]);

  if (loading) {
    return <CircularProgress size={20} />;
  }

  if (skills.length === 0) {
    return (
      <Typography variant="body2" color="textSecondary">
        No skills found for this user yet...
      </Typography>
    );
  }

  return (
    <Box display="flex">
      {skills.map((skill, index) => (
        <Chip
          key={index}
          label={`${skill.name}`}
          size="small"
          className={classes.skillChip}
        />
      ))}
    </Box>
  );
};

const columns: TableColumn<Entity>[] = [
  {
    title: 'Name',
    field: 'metadata.name',
    highlight: true,
    render: (entity: Entity) => <EntityRefLink entityRef={entity} />,
  },
  {
    title: 'Status',
    field: 'spec.status',
    render: (entity: Entity) => {
      const classes = useStyles();
      const isActive = entity.metadata.annotations?.['monday.com/active'] === 'true';

      return (
        <Chip
          label={isActive ? 'Active' : 'Available'}
          className={isActive ? classes.activeChip : classes.availableChip}
          size="small"
        />
      );
    },
  },
  {
    title: 'Top Skills',
    field: 'metadata.name',
    render: (entity: Entity) => (
      <TopSkillsCell userId={entity.metadata.name} />
    ),
  },
];

interface EntityTableComponentProps {
  searchQuery: string;
  entitiesPerPage: number;
  statusFilter: string;
}

const EntityTableComponent: React.FC<EntityTableComponentProps> = ({
  searchQuery,
  entitiesPerPage,
  statusFilter,
}) => {
  const catalogApi = useApi(catalogApiRef);
  const [entities, setEntities] = React.useState<Entity[]>([]);
  const [filteredEntities, setFilteredEntities] = React.useState<Entity[]>([]);
  const [engagements, setEngagements] = React.useState<Entity[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  // Pagination state
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  React.useEffect(() => {
    let isMounted = true;

    const fetchEntities = async () => {
      try {
        const response = await catalogApi.getEntities();

        if (isMounted) {
          // Filter entities of Kind 'User'
          let initialEntities = response.items.filter(
            (entity: Entity) => entity.kind === 'User'
          );

          setEntities(initialEntities);
          setFilteredEntities(initialEntities);
        }
      } catch (error) {
        console.error('Failed to fetch entities:', error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    const fetchEngagements = async () => {
      try {
        const response = await catalogApi.getEntities({
          filter: { kind: 'Resource' },
        });
        if (isMounted) {
          setEngagements(response.items);
        }
      } catch (error) {
        console.error('Failed to fetch engagements:', error);
      }
    };

    fetchEntities();
    fetchEngagements();

    return () => {
      isMounted = false;
    };
  }, [catalogApi]);

  React.useEffect(() => {
    let result = [...entities];

    // Apply search query
    if (searchQuery) {
      const lowerSearchQuery = searchQuery.toLowerCase();
      result = result.filter((entity: Entity) =>
        (entity.spec?.profile as { displayName?: string })?.displayName?.toLowerCase()?.includes(lowerSearchQuery) ?? false,
      );
    }

    // Apply status filter
    if (statusFilter !== 'All') {
      result = result.filter(entity => {
        const isActive = entity.metadata.annotations?.['monday.com/active'] === 'true';
        return statusFilter === 'Active' ? isActive : !isActive;
      });
    }

    setFilteredEntities(result);
  }, [entities, searchQuery, statusFilter, engagements]);

  // Reset currentPage when filters change
  React.useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery, entitiesPerPage, statusFilter]);

  // Calculate the entities to display on the current page
  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = filteredEntities.slice(indexOfFirstEntity, indexOfLastEntity);

  // Calculate total pages
  const totalPages = Math.ceil(filteredEntities.length / entitiesPerPage);

  // Pagination controls
  const renderPaginationControls = () => {
    return (
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: '16px' }}
      >
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(prev => prev - 1)}
          >
            Previous
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            Page {currentPage} of {totalPages}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            disabled={currentPage === totalPages || totalPages === 0}
            onClick={() => setCurrentPage(prev => prev + 1)}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    );
  };

  if (loading) {
    return (
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        minHeight="400px"
      >
        <CircularProgress 
          size={40}
          color="primary"
        />
      </Box>
    );
  }

  if (filteredEntities.length === 0) {
    return <div>No entities found.</div>;
  }

  return (
    <>
      <EntityTable
        title="Consultants Catalog"
        entities={currentEntities}
        columns={columns}
      />
      {renderPaginationControls()}
    </>
  );
};

const ConsultantsCatalogPage: React.FC = () => {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = React.useState<string>('');
  const [isSearching, setIsSearching] = React.useState(false);
  const [entitiesPerPage, setEntitiesPerPage] = React.useState<number>(10);
  const [statusFilter, setStatusFilter] = React.useState<string>('All');

  const discoveryApi = useApi(discoveryApiRef);

  // Add debounce for search
  React.useEffect(() => {
    setIsSearching(true);
    const timer = setTimeout(() => {
      setIsSearching(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchQuery]);

  return (
    <Content>
      <Grid container spacing={2} alignItems="flex-start" className={classes.filterGrid}>
        <Grid item xs={12} sm={6} md={3}>
          <div className={classes.searchWrapper}>
            <TextField
              label="Search by Name"
              variant="outlined"
              fullWidth
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              InputProps={{
                endAdornment: searchQuery && (
                  <ClearIcon
                    className={classes.searchIcon}
                    onClick={() => setSearchQuery('')}
                  />
                ),
              }}
            />
            {isSearching && (
              <CircularProgress
                size={20}
                className={classes.searchProgress}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="status-filter-label">Status</InputLabel>
            <Select
              labelId="status-filter-label"
              label="Status"
              value={statusFilter}
              onChange={e => setStatusFilter(e.target.value as string)}
            >
              <MenuItem value="All">All Status</MenuItem>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Available">Available</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="entities-per-page-label">
              Entities per page
            </InputLabel>
            <Select
              labelId="entities-per-page-label"
              label="Entities per page"
              value={entitiesPerPage.toString()}
              onChange={e =>
                setEntitiesPerPage(parseInt(e.target.value as string, 10))
              }
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box className={classes.tableContainer}>
        <EntityTableComponent
          searchQuery={searchQuery}
          entitiesPerPage={entitiesPerPage}
          statusFilter={statusFilter}
        />
      </Box>
    </Content>
  );
};

export default ConsultantsCatalogPage;
