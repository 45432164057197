import React from 'react';
import { 
  Grid,
  Card, 
  CardHeader, 
  CardContent, 
  makeStyles,
  Chip,
  Typography,
  List,
  ListItem,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { useEntity, EntityRefLink } from '@backstage/plugin-catalog-react';
import {
  EntityLayout,
  EntitySwitch,
  EntityOrphanWarning,
  EntityRelationWarning,
  EntityProcessingErrorsPanel,
  isOrphan,
  hasRelationWarnings,
  hasCatalogProcessingErrors,
} from '@backstage/plugin-catalog';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import LaunchIcon from '@material-ui/icons/Launch';

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    boxShadow: theme.shadows[2],
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  label: {
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  chipContinue: {
    backgroundColor: '#1abc9c',
    color: theme.palette.common.white,
  },
  chipSleep: {
    backgroundColor: '#f39c12',
    color: theme.palette.common.white,
  },
  chipStart: {
    backgroundColor: '#3498db',
    color: theme.palette.common.white,
  },
  chipTBD: {
    backgroundColor: '#95a5a6',
    color: theme.palette.common.white,
  },
  chipTest: {
    backgroundColor: '#9b59b6',
    color: theme.palette.common.white,
  },
  chipDefault: {
    backgroundColor: '#34495e',
    color: theme.palette.common.white,
  },
  metadataItem: {
    marginBottom: theme.spacing(2),
  },
  portfolioButton: {
    marginLeft: theme.spacing(1),
    textTransform: 'none',
  },
  usersList: {
    padding: 0,
  },
  userItem: {
    padding: theme.spacing(1, 0),
  },
  userContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  userAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: '50%',
  },
  userLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const UNKNOWN_VALUE = '🤔';

const UserDisplay = ({ userName }: { userName: string }) => {
  const classes = useStyles();
  const catalogApi = useApi(catalogApiRef);
  const [userEntity, setUserEntity] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading(true);
        // Get all users and filter by display name
        const { items } = await catalogApi.getEntities({
          filter: {
            kind: 'User',
          },
        });
        
        const matchedUser = items.find(user => 
          (user.spec?.profile && typeof user.spec.profile === 'object' && 'displayName' in user.spec.profile && typeof user.spec.profile.displayName === 'string' && user.spec.profile.displayName.toLowerCase() === userName.trim().toLowerCase()) ||
          user.metadata.name.toLowerCase() === userName.trim().toLowerCase()
        );

        if (matchedUser) {
          setUserEntity(matchedUser);
        }
      } catch (error) {
        console.error('Failed to fetch user:', error);
      } finally {
        setLoading(false);
      }
    };

    if (userName) {
      fetchUser();
    }
  }, [catalogApi, userName]);

  if (loading) {
    return <CircularProgress size={16} />;
  }

  if (userEntity) {
    const displayName = userEntity.spec?.profile?.displayName || userEntity.metadata.name;
    const avatarUrl = userEntity.spec?.profile?.picture;
    
    return (
      <div className={classes.userContainer}>
        {avatarUrl && (
          <img 
            src={avatarUrl} 
            alt={displayName}
            className={classes.userAvatar}
          />
        )}
        <EntityRefLink 
          entityRef={userEntity} 
          className={classes.userLink}
        >
          {displayName}
        </EntityRefLink>
      </div>
    );
  }

  return <Typography component="span">{userName.trim()}</Typography>;
};

const MultipleUsersDisplay = ({ users }: { users: string }) => {
  const classes = useStyles();
  if (!users) return <Typography>{UNKNOWN_VALUE}</Typography>;

  const userList = users.split(',');

  return (
    <List className={classes.usersList}>
      {userList.map((user, index) => (
        <ListItem key={index} className={classes.userItem}>
          <UserDisplay userName={user} />
        </ListItem>
      ))}
    </List>
  );
};

// Update OwnerCard component
const OwnerCard = ({ owner }: { owner: string }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardHeader 
        title="Owner"
        titleTypographyProps={{ variant: 'h6' }}
      />
      <CardContent>
        {owner ? (
          <MultipleUsersDisplay users={owner} />
        ) : (
          <Typography color="textSecondary">{UNKNOWN_VALUE}</Typography>
        )}
      </CardContent>
    </Card>
  );
};

// Update TrainersCard component
const TrainersCard = ({ trainers }: { trainers: string }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardHeader 
        title="Trainers"
        titleTypographyProps={{ variant: 'h6' }}
      />
      <CardContent>
        {trainers ? (
          <MultipleUsersDisplay users={trainers} />
        ) : (
          <Typography color="textSecondary">{UNKNOWN_VALUE}</Typography>
        )}
      </CardContent>
    </Card>
  );
};

const TrainingContent = ({ entity }: { entity: any }) => {
  const classes = useStyles();
  
  const getStatusChipClass = (status: string) => {
    switch (status?.toLowerCase()) {
      case 'continue': return classes.chipContinue;
      case 'sleep (shop)': return classes.chipSleep;
      case 'start': return classes.chipStart;
      case 'tbd': return classes.chipTBD;
      case 'test': return classes.chipTest;
      default: return classes.chipDefault;
    }
  };

  const metadata = [
    {
      label: 'Name',
      value: entity.metadata.title,
    },
    {
      label: 'Service Line',
      value: entity.metadata.annotations?.['monday.com.field-serviceline'] || '-',
    },
    {
      label: 'Status',
      value: entity.metadata.annotations?.['monday.com.field-status'],
      isChip: true,
    },
    {
      label: 'Environment',
      value: entity.metadata.annotations?.['monday.com.field-environment'] || '-',
    },
    {
      label: 'Lifecycle',
      value: entity.metadata.annotations?.['monday.com.field-lifecycle'] || '-',
    },
    {
      label: 'Portfolio',
      value: entity.metadata.annotations?.['monday.com.field-portfolio'] || '-',
    },
  ];

  const entityWarningContent = (
    <>
      <EntitySwitch>
        <EntitySwitch.Case if={isOrphan}>
          <Grid item xs={12}>
            <EntityOrphanWarning />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>

      <EntitySwitch>
        <EntitySwitch.Case if={hasRelationWarnings}>
          <Grid item xs={12}>
            <EntityRelationWarning />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>

      <EntitySwitch>
        <EntitySwitch.Case if={hasCatalogProcessingErrors}>
          <Grid item xs={12}>
            <EntityProcessingErrorsPanel />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
    </>
  );

  // Add URL validation helper after imports
  const isValidUrl = (string: string) => {
    try {
      return Boolean(new URL(string));
    } catch (_) {
      return false;
    }
  };

  return (
    <Grid container spacing={3}>
      {entityWarningContent}
      <Grid item xs={12} md={8}>
        <Card className={classes.card}>
          <CardHeader 
            title="Training Details"
            titleTypographyProps={{ variant: 'h6' }}
          />
          <CardContent className={classes.cardContent}>
          {metadata.map((item, index) => (
              <div key={index} className={classes.metadataItem}>
                <Typography 
                  component="span" 
                  className={classes.label}
                  color="textSecondary"
                >
                  {item.label}:
                </Typography>
                {(() => {
                  if (item.label === 'Portfolio' && isValidUrl(item.value)) {
                    return (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.portfolioButton}
                        href={item.value}
                        target="_blank"
                        rel="noopener noreferrer"
                        endIcon={<LaunchIcon />}
                      >
                        View Portfolio
                      </Button>
                    );
                  }
                  if (item.isChip) {
                    return (
                      <Chip
                        label={item.value || UNKNOWN_VALUE}
                        size="small"
                        className={`${classes.chip} ${getStatusChipClass(item.value)}`}
                      />
                    );
                  }
                  return (
                    <Typography component="span">
                      {item.value === '-' ? UNKNOWN_VALUE : item.value}
                    </Typography>
                  );
                })()}
              </div>
            ))}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <OwnerCard owner={entity.metadata.annotations?.['monday.com.field-owner']} />
          </Grid>
          <Grid item xs={12}>
            <TrainersCard trainers={entity.metadata.annotations?.['monday.com.field-trainers']} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const TrainingEntityPage = () => {
  const { entity } = useEntity();
  return (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <TrainingContent entity={entity} />
      </EntityLayout.Route>
    </EntityLayout>
  );
};
