// ConsultantPage.tsx
import React from 'react';
import { Grid } from '@material-ui/core';
import {
  EntityLayout,
  EntitySwitch,
  EntityOrphanWarning,
  EntityRelationWarning,
  EntityProcessingErrorsPanel,
  isOrphan,
  hasRelationWarnings,
  hasCatalogProcessingErrors,
} from '@backstage/plugin-catalog';
import {
  EntityUserProfileCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import { useEntity } from '@backstage/plugin-catalog-react';
import { makeStyles } from '@material-ui/core';
import { SharepointFrontendPage } from '@internal/backstage-plugin-sharepoint-frontend';
import { SkillsFrontendPage } from '@internal/backstage-plugin-skills-frontend';

import { useApi, configApiRef, identityApiRef } from '@backstage/core-plugin-api';

// Import the ConsultantEngagementsPanel which displays the engagements of a consultant
import { ConsultantEngagementsPanel } from './ConsultantEngagementsPanel';
import { ConsultantTrainingsPanel } from './ConsultantTrainingsPanel';


const useStyles = makeStyles(theme => ({
  iframe: {
    width: '100%',
    height: '600px',
    border: 'none',
  },
  errorCard: {
    backgroundColor: theme.palette.error.light,
  },
  warningCard: {
    backgroundColor: theme.palette.warning.light,
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.warning.main}`,
    borderRadius: theme.shape.borderRadius,
  },
  warningIcon: {
    color: theme.palette.warning.dark,
    fontSize: 40,
    marginBottom: theme.spacing(1),
  },
  warningContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: theme.spacing(1),
  },
  warningText: {
    color: theme.palette.text.primary, // Better contrast
  },
}));

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasRelationWarnings}>
        <Grid item xs={12}>
          <EntityRelationWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

interface EntityProfile {
  profile?: {
    displayName?: string;
  };
}

export const ConsultantPage: React.FC = () => {
  const { entity } = useEntity();
  const config = useApi(configApiRef);
  const identity = useApi(identityApiRef);
  const displayName = (entity.spec as EntityProfile)?.profile?.displayName;

  return (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3}>
          {entityWarningContent}
          <Grid item xs={12} md={6}>
            <EntityUserProfileCard variant="gridItem" />
          </Grid>
          <Grid item xs={12} md={6}>
            <EntityOwnershipCard variant="gridItem" />
          </Grid>
          <Grid item xs={12} md={6}>
            <SkillsFrontendPage />
          </Grid>
          <Grid item xs={12} md={6}>
            <ConsultantEngagementsPanel consultantName={displayName} />
          </Grid>
        </Grid>
      </EntityLayout.Route>
      <EntityLayout.Route path="/cv" title="CV">
        <Grid container spacing={3}>
          <Grid item xs={12}>
          <SharepointFrontendPage 
            path={`${displayName}`}
          />
          </Grid>
        </Grid>
      </EntityLayout.Route>
      <EntityLayout.Route path="/trainings" title="Trainings">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ConsultantTrainingsPanel userName={displayName || ''} />
          </Grid>
        </Grid>
      </EntityLayout.Route>
    </EntityLayout>
  );
};

export default ConsultantPage;
