import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { SkillsApi } from './types';

export class SkillsClient implements SkillsApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  private async getBaseUrl() {
    return await this.discoveryApi.getBaseUrl('skills');
  }

  async getSkills() {
    const response = await this.fetchApi.fetch(`${await this.getBaseUrl()}/skills`);
    return await response.json();
  }

  async getProficiencies(proficiency: number) {
    const response = await this.fetchApi.fetch(
        `${await this.getBaseUrl()}/proficiencies`,
        {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ proficiency: proficiency }),
        }
    );
    return await response.json();
  }

  async getUserSkills(userId: string) {
    const response = await this.fetchApi.fetch(`${await this.getBaseUrl()}/users/${userId}/skills`);
    return await response.json();
  }

  async addUserSkill(userId: string, name: string, proficiency: number) {
    const response = await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/users/${userId}/skills`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name, proficiency }),
      },
    );
    return await response.json();
  }

  async removeUserSkill(userId: string, name: string) {
    await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/users/${userId}/skills`,
      {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ name }),
      },
    );
  }
}
