import {
  createPlugin,
  createRoutableExtension,
  createApiFactory,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { skillsApiRef, SkillsClient } from './api';

export const skillsFrontendPlugin = createPlugin({
  id: 'skills-frontend',
  apis: [
    createApiFactory({
      api: skillsApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new SkillsClient({ discoveryApi, fetchApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const SkillsFrontendPage = skillsFrontendPlugin.provide(
  createRoutableExtension({
    name: 'SkillsFrontendPage',
    component: () =>
      import('./components/SkillsForm').then(m => m.SkillsForm),
    mountPoint: rootRouteRef,
  }),
);
