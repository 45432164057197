// TrainingCatalogPage.tsx

import React from 'react';
import { Content } from '@backstage/core-components';
import { EntityTable, EntityRefLink } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import { TableColumn } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Chip,
  Box,
  CircularProgress,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(theme => ({
  filterGrid: {
    marginBottom: theme.spacing(4),
  },
  tableContainer: {
    marginTop: theme.spacing(4),
  },
  searchWrapper: {
    position: 'relative',
    width: '100%',
  },
  searchProgress: {
    position: 'absolute',
    right: '48px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  searchIcon: {
    position: 'absolute',
    right: '8px',
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
  },
  paginationContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0, 1),
    },
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  chipContinue: {
    backgroundColor: '#1abc9c',
    color: theme.palette.common.white,
  },
  chipSleep: {
    backgroundColor: '#f39c12',
    color: theme.palette.common.white,
  },
  chipStart: {
    backgroundColor: '#3498db',
    color: theme.palette.common.white,
  },
  chipTBD: {
    backgroundColor: '#95a5a6',
    color: theme.palette.common.white,
  },
  chipTest: {
    backgroundColor: '#9b59b6',
    color: theme.palette.common.white,
  },
  chipDefault: {
    backgroundColor: '#34495e',
    color: theme.palette.common.white,
  },
}));

const columns: TableColumn<Entity>[] = [
  {
    title: 'Name',
    field: 'metadata.name',
    highlight: true,
    render: (entity: Entity) => <EntityRefLink entityRef={entity} />,
  },
  {
    title: 'Service Line',
    field: 'metadata.annotations["monday.com.field-serviceline"]',
    render: (entity: Entity) => entity.metadata.annotations?.['monday.com.field-serviceline'] || '-',
  },
  {
    title: 'Status',
    field: 'metadata.annotations["monday.com.field-status"]',
    render: (entity: Entity) => {
      const classes = useStyles();
      const status = entity.metadata.annotations?.['monday.com.field-status'];
      
      let chipClass = classes.chipDefault;
      switch (status?.toLowerCase()) {
        case 'continue':
          chipClass = classes.chipContinue;
          break;
        case 'sleep (shop)':
          chipClass = classes.chipSleep;
          break;
        case 'start':
          chipClass = classes.chipStart;
          break;
        case 'tbd':
          chipClass = classes.chipTBD;
          break;
        case 'test':
          chipClass = classes.chipTest;
          break;
        default:
          chipClass = classes.chipDefault;
          break;
      }

      return (
        <Chip
          label={status || 'Unknown'}
          size="small"
          className={`${classes.chip} ${chipClass}`}
        />
      );
    },
  },
  {
    title: 'Owner',
    field: 'metadata.annotations["monday.com.field-owner"]',
    render: (entity: Entity) => entity.metadata.annotations?.['monday.com.field-owner'] || '-',
  },
];

const TrainingCatalogPage: React.FC = () => {
  const classes = useStyles();
  const catalogApi = useApi(catalogApiRef);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [serviceLineFilter, setServiceLineFilter] = React.useState('All');
  const [statusFilter, setStatusFilter] = React.useState('All');
  const [entities, setEntities] = React.useState<Entity[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [serviceLines, setServiceLines] = React.useState<string[]>([]);
  const [statuses, setStatuses] = React.useState<string[]>([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = React.useState(10);

  // Fetch entities and extract filter options
  React.useEffect(() => {
    const fetchEntities = async () => {
      try {
        const response = await catalogApi.getEntities({
          filter: {
            kind: 'Resource',
            'spec.type': 'training',
          },
        });

        const uniqueServiceLines = new Set<string>();
        const uniqueStatuses = new Set<string>();

        response.items.forEach((entity: Entity) => {
          const serviceLine = entity.metadata.annotations?.['monday.com.field-serviceline'];
          const status = entity.metadata.annotations?.['monday.com.field-status'];
          
          if (serviceLine) uniqueServiceLines.add(serviceLine);
          if (status) uniqueStatuses.add(status);
        });

        setEntities(response.items);
        setServiceLines(Array.from(uniqueServiceLines).sort());
        setStatuses(Array.from(uniqueStatuses).sort());
      } catch (error) {
        console.error('Failed to fetch trainings:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEntities();
  }, [catalogApi]);

  const filteredEntities = entities.filter(entity => {
    const matchesSearch = searchQuery
      ? entity.metadata.name.toLowerCase().includes(searchQuery.toLowerCase())
      : true;

    const matchesServiceLine = serviceLineFilter === 'All'
      ? true
      : entity.metadata.annotations?.['monday.com.field-serviceline'] === serviceLineFilter;

    const matchesStatus = statusFilter === 'All'
      ? true
      : entity.metadata.annotations?.['monday.com.field-status'] === statusFilter;

    return matchesSearch && matchesServiceLine && matchesStatus;
  });

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = filteredEntities.slice(indexOfFirstEntity, indexOfLastEntity);
  const totalPages = Math.ceil(filteredEntities.length / entitiesPerPage);

  return (
    <Content>
      <Grid container spacing={2} alignItems="flex-start" className={classes.filterGrid}>
        <Grid item xs={12} sm={6} md={3}>
          <div className={classes.searchWrapper}>
            <TextField
              label="Search by Name"
              variant="outlined"
              fullWidth
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              InputProps={{
                endAdornment: searchQuery && (
                  <ClearIcon
                    className={classes.searchIcon}
                    onClick={() => setSearchQuery('')}
                  />
                ),
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Service Line</InputLabel>
            <Select
              value={serviceLineFilter}
              onChange={e => setServiceLineFilter(e.target.value as string)}
              label="Service Line"
            >
              <MenuItem value="All">All Service Lines</MenuItem>
              {serviceLines.map(line => (
                <MenuItem key={line} value={line}>{line}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={statusFilter}
              onChange={e => setStatusFilter(e.target.value as string)}
              label="Status"
            >
              <MenuItem value="All">All Statuses</MenuItem>
              {statuses.map(status => (
                <MenuItem key={status} value={status}>{status}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Entries per page</InputLabel>
            <Select
              value={entitiesPerPage}
              onChange={e => {
                setEntitiesPerPage(Number(e.target.value));
                setCurrentPage(1);
              }}
              label="Entries per page"
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box className={classes.tableContainer}>
        {(() => {
          if (loading) {
            return (
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
                <CircularProgress />
              </Box>
            );
          }
          
          if (filteredEntities.length > 0) {
            return (
              <>
                <EntityTable
                  title="Training Catalog"
                  entities={currentEntities}
                  columns={columns}
                />
                <Box className={classes.paginationContainer}>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(prev => prev - 1)}
                  >
                    Previous
                  </Button>
                  <Typography variant="body1">
                    Page {currentPage} of {totalPages}
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={currentPage === totalPages}
                    onClick={() => setCurrentPage(prev => prev + 1)}
                  >
                    Next
                  </Button>
                </Box>
              </>
            );
          }
          
          return (
            <Typography variant="body1" align="center">
              No trainings found matching the current filters.
            </Typography>
          );
        })()}
      </Box>
    </Content>
  );
};

export default TrainingCatalogPage;
